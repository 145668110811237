import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import PropsList from '~/src/components/props-list';
import { COMMON, LAYOUT, BORDER, TYPOGRAPHY } from '~/src/components/react-constants';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Note variant="warning" mdxType="Note">
      <p>{`System props are deprecated in all components except `}<a parentName="p" {...{
          "href": "/components/box/react"
        }}>{`Box`}</a>{` and `}<a parentName="p" {...{
          "href": "/components/text/react"
        }}>{`Text`}</a>{`. Please use the `}<a parentName="p" {...{
          "href": "/guides/react/overriding-styles"
        }}><inlineCode parentName="a">{`sx`}</inlineCode>{` prop`}</a>{` instead.`}</p>
    </Note>
    <p>{`Primer React components utilize what we call "system props" to apply a standard set of props to each component. Using `}<a parentName="p" {...{
        "href": "https://github.com/jxnblk/styled-system"
      }}>{`styled-system`}</a>{`, groups of props are automatically applied to each component. Most components get the `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` set of props which give the component access to color and space props (margin, padding, color and background color). These groups correspond to the `}<inlineCode parentName="p">{`color`}</inlineCode>{` and `}<inlineCode parentName="p">{`space`}</inlineCode>{` functions from `}<inlineCode parentName="p">{`styled-system`}</inlineCode>{` which can be referenced in the styled system `}<a parentName="p" {...{
        "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#core"
      }}>{`table of style functions`}</a>{`.`}</p>
    <p>{`To check which system props each component includes, check the documentation for that component.`}</p>
    <h3>{`The `}<inlineCode parentName="h3">{`as`}</inlineCode>{` prop`}</h3>
    <p>{`All Primer React components have access to the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop, provided by `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/docs/api#as-polymorphic-prop"
      }}>{`styled-components`}</a>{`. We use the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop to render a component with the styles of the passed component in `}<inlineCode parentName="p">{`as`}</inlineCode>{`, but with the system props of the base component.`}</p>
    <h3>{`System Prop Categories`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`styled-system docs`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`COMMON`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><PropsList systemProps={COMMON} mdxType="PropsList" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#core"
            }}>{`styled-system core docs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TYPOGRAPHY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><PropsList systemProps={TYPOGRAPHY} mdxType="PropsList" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#typography"
            }}>{`styled-system typography docs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`BORDER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><PropsList systemProps={BORDER} mdxType="PropsList" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#border"
            }}>{`styled-system border docs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LAYOUT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><PropsList systemProps={LAYOUT} mdxType="PropsList" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#layout"
            }}>{`styled-system layout docs`}</a>{` `}<br />{` `}<a parentName="td" {...{
              "href": "https://github.com/jxnblk/styled-system/blob/master/docs/table.md#misc"
            }}>{`styled-system misc docs`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      